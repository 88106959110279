import Navbar from "../components/navbar";

function About() {
    return(
        <div>
            <Navbar/>
            About
        </div>
    )
}

export default About;