import Navbar from "../components/navbar";

function Partners() {
    return(
        <div>
            <Navbar/>
            Partners
        </div>
    )
}

export default Partners;