import Navbar from "../components/navbar";

function Resources() {
    return(
        <div>
            <Navbar/>
            Resources
        </div>
    )
}

export default Resources;