import Navbar from "../components/navbar";

function Contact() {
    return(
        <div>
            <Navbar/>
            Contact
        </div>
    )
}

export default Contact;